import React from 'react';
import StickyBox from 'react-sticky-box';
import { graphql } from 'gatsby';
import DocFeedback from '@components/DocFeedback';
import DocPageNav from '@components/DocPageNav';
import DocPrevNextNav from '@components/DocPrevNextNav';
import DocsSitemap from '@components/DocsSitemap';
import SliceZone from '@components/SliceZone';
import { DocIdMap } from '@utils/typings/custom';
import {
  PrismicDocPageFragmentFragment,
  PrismicDocsSitemapFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  docPage: PrismicDocPageFragmentFragment;
  docsSitemap: PrismicDocsSitemapFragmentFragment;
  orderedPageIds: string[];
  docIdMap: DocIdMap;
}
export default function DocContentLayout({
  docPage,
  docsSitemap,
  orderedPageIds,
  docIdMap,
}: Props) {
  const group = docsSitemap.data.body.find((groupItem) =>
    groupItem.items.find((item) => item.doc_page.id === docPage.prismicId),
  );

  const breadcrumb = group
    ? `${group.primary.name.text} / ${docPage.data.title.text}`
    : docPage.data.title.text;

  const pageIndex = orderedPageIds.indexOf(docPage.prismicId);

  return (
    <div className={styles.docPage}>
      <div className={styles.sitemap}>
        <StickyBox offsetTop={72} className={styles.sitemapBox}>
          <DocsSitemap
            sitemap={docsSitemap}
            docIdMap={docIdMap}
            activeUrl={docPage.url}
          />
        </StickyBox>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.richTextAndNav}>
          <main className={styles.container}>
            <div className={styles.content}>
              <p className={styles.breadcrumb}>{breadcrumb}</p>
              <h1 className={styles.heading}>{docPage.data.title.text}</h1>
              <SliceZone slices={docPage.data.body} />
              <div className={styles.feedback}>
                <DocFeedback id={docPage.id} />
              </div>
            </div>
          </main>
          <DocPageNav slices={docPage.data.body} />
        </div>
        <DocPrevNextNav
          prev={docIdMap[orderedPageIds[pageIndex - 1]]}
          next={docIdMap[orderedPageIds[pageIndex + 1]]}
        />
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PrismicDocPageFragment on PrismicDocPage {
    _previewable
    url
    uid
    type
    id
    prismicId
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    data {
      title {
        text
      }
      body {
        ... on PrismicDocPageDataBodyDocsRichText {
          id
          slice_type
          slice_label
          primary {
            rich_text {
              richText
            }
          }
        }
        ... on PrismicDocPageDataBodyImportantBlockQuote {
          id
          slice_type
          slice_label
          primary {
            text {
              text
            }
            heading {
              text
            }
          }
        }
        ... on PrismicDocPageDataBodyDocsFullWidthImage {
          id
          slice_type
          slice_label
          primary {
            background_image_position
            image {
              url
              alt
            }
          }
        }
      }
      metadata_image {
        alt
        url
      }
      metadata_description {
        text
      }
      metadata_title {
        text
      }
    }
  }
`;
