import React from 'react';
import { Link } from 'gatsby';
import Authors from '@components/BlogAuthors';
import { publishDate } from '@utils/publishDate';
import { readingTime } from '@utils/readingTime';
import {
  PrismicAuthorFragmentFragment,
  PrismicBlogPageFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  nextPage?: PrismicBlogPageFragmentFragment;
  authors?: PrismicAuthorFragmentFragment[];
};

export default function BlogKeepReading({ nextPage, authors }: Props) {
  const readTime = readingTime(nextPage);

  return (
    <div className={styles.container}>
      <div className={styles.empty} />
      <h6 className={styles.keepReading}>Keep reading...</h6>
      <div className={styles.empty} />
      <div className={styles.metadata}>
        <div className={styles.dateAndTime}>
          <span className={styles.date}>{publishDate(nextPage)}</span>
          <span className={styles.readTime}>{readTime}</span>
        </div>
        <div className={styles.tags}>
          {nextPage.tags.map((tag) => (
            <span key={tag}>{tag}</span>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        <Link to={nextPage.url} className={styles.main}>
          <h2 className={styles.title}>{nextPage.data.title.text}</h2>
          <p className={styles.description}>{nextPage.data.subtitle.text}</p>
        </Link>
      </div>
      <div>
        <Authors authors={authors} />
      </div>
    </div>
  );
}
