import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import MarketingLayout from '@components/MarketingLayout';
import SliceZone from '@components/SliceZone';
import linkResolver from '@utils/linkResolver';
import { HomepageTemplateQuery } from '@typings/prismic-types';
import { trackEvent } from '@analytics/trackEvent';
import { PryAnalyticsEvents } from '@analytics/events';

type Props = PageProps<HomepageTemplateQuery>;

export const HomepageTemplate = ({ data }: Props) => {
  // This is a debugger to test Segment integration. Will remove soon.
  useEffect(() => {
    if (data) {
      trackEvent(PryAnalyticsEvents.SegmentIntegrationTest);
    }
  }, [data]);

  if (!data) return null;

  const homepage = data.prismicHomepage;
  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  const { lang, type, url } = homepage;
  const alternateLanguages = homepage.alternate_languages;
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <MarketingLayout
      banner={data.prismicBanner}
      nav={nav}
      footer={footer}
      activeDocMeta={activeDoc}
      data={homepage.data}
    >
      <SliceZone slices={homepage.data.body} />
    </MarketingLayout>
  );
};

export const query = graphql`
  query HomepageTemplate($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
      }
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        page_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicHomepageDataBodyHomepageHero {
            id
            slice_label
            slice_type
            primary {
              section_title {
                html
                richText
                text
              }
              text {
                html
                richText
                text
              }
              cta_link {
                url
                type
                uid
              }
              cta_text {
                html
                richText
                text
              }
              video_link {
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyCtaBanner {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
              background
              heading {
                html
                richText
                text
              }
              primary_cta_link {
                url
                type
                uid
              }
              primary_cta_label
              primary_cta_icon
              secondary_cta_link {
                url
                type
                uid
              }
              secondary_cta_label
              secondary_cta_icon
            }
          }
          ... on PrismicHomepageDataBodyCustomerLogos {
            id
            slice_type
            slice_label
            primary {
              eyebrow_headline {
                html
                richText
                text
              }
            }
            items {
              logo {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              link {
                url
                type
                uid
              }
            }
          }
          ... on PrismicHomepageDataBodyCardsSection {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
              headline {
                html
                richText
                text
              }
              description {
                html
                richText
                text
              }
              background
              headline_position
              number_of_columns
              card_text_position
              primary_cta_link {
                url
                type
                uid
              }
              primary_cta_label
              secondary_cta_link {
                url
                type
                uid
              }
              secondary_cta_label
            }
            items {
              card_image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
              card_heading
              card_body
              card_link {
                url
                type
                uid
              }
              card_link_label
            }
          }
          ...PrismicHomepageTestimonialCarouselFragment
          ... on PrismicHomepageDataBodyTabsEmbed {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
              heading {
                html
                richText
                text
              }
              mobile_image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
            items {
              tab_label
              iframe_src {
                url
                type
                uid
              }
            }
          }
          ... on PrismicHomepageDataBodyFullWidthImage {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
              background
              heading {
                html
                richText
                text
              }
              subheading {
                html
                richText
                text
              }
              subheading_position
              cta_link {
                url
                type
                uid
              }
              cta_label
              image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              gradient_image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              mobile_image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              mobile_gradient_image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              list_item
            }
          }
          ... on PrismicHomepageDataBodyWistiaVideo {
            id
            slice_type
            slice_label
            primary {
              video_link {
                url
              }
            }
          }
          ...PrismicHomepageInfoWithImageFragment
        }
      }
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(HomepageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
