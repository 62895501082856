import React from 'react';
import Banner from '@components/Banner';
import DocsHeader from '@components/DocsHeader';
import Footer from '@components/Footer';
import Seo from '@components/Seo';
import { DocIdMap } from '@utils/typings/custom';
import {
  FooterFragmentFragment,
  PrismicDocsSitemapFragmentFragment,
  PrismicBannerFragmentFragment,
} from '@typings/prismic-types';
import './styles.scss';

type Props = {
  docsSitemap: PrismicDocsSitemapFragmentFragment;
  docIdMap: DocIdMap;
  banner: PrismicBannerFragmentFragment;
  footer: FooterFragmentFragment;
  activeUrl: string;
  firstDocUrl: string;
  docMetadata: {
    title: string;
    description: string;
    image: string;
  };
  children: React.ReactNode;
};

const DocLayout = ({
  docsSitemap,
  docIdMap,
  banner,
  footer,
  activeUrl,
  firstDocUrl,
  docMetadata,
  children,
}: Props) => {
  return (
    <div className="typography">
      <Seo
        title={docMetadata.title}
        description={docMetadata.description}
        image={docMetadata.image}
      />
      <Banner banner={banner} />
      <DocsHeader
        docsSitemap={docsSitemap}
        docIdMap={docIdMap}
        activeUrl={activeUrl}
        firstDocUrl={firstDocUrl}
      />
      <main>{children}</main>
      <Footer footer={footer} />
    </div>
  );
};

DocLayout.propTypes = {
  children: Number,
};

export default DocLayout;
