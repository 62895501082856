// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--0JUne";
export var docPage = "styles-module--doc-page--ACJce";
export var heading = "styles-module--heading--Nz-TU";
export var sitemap = "styles-module--sitemap--90Aki";
export var sitemapBox = "styles-module--sitemap-box--6nhHR";
export var rightSection = "styles-module--right-section--o176e";
export var container = "styles-module--container--CSmrZ";
export var richTextAndNav = "styles-module--rich-text-and-nav--Dul1x";
export var content = "styles-module--content--iJTrk";
export var breadcrumb = "styles-module--breadcrumb--zannf";
export var feedback = "styles-module--feedback--mOJep";