import React from 'react';
import FacebookIcon from '@icons/facebook.svg';
import LinkedInIcon from '@icons/linkedin.svg';
import TwitterIcon from '@icons/twitter.svg';
import * as styles from './styles.module.scss';

interface Props {
  activeBlogMeta: {
    url: string;
    title: string;
    description: string;
    imageUrl: string;
    lang: string;
  };
}
export default function BlogShareLinks({ activeBlogMeta }: Props) {
  const { url, title, description } = activeBlogMeta;
  return (
    <div className={styles.share}>
      <a
        className={styles.link}
        href={`https://facebook.com/sharer/sharer.php?u=https://pry.co${url}`}
        target="_blank"
        rel="noreferrer"
        aria-label="Share on Facebook"
      >
        <FacebookIcon />
      </a>
      <a
        className={styles.link}
        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://pry.co${url}&title=${encodeURIComponent(
          title,
        )}&summary=${encodeURIComponent(description)}`}
        target="_blank"
        rel="noreferrer"
        aria-label="Share on LinkedIn"
      >
        <LinkedInIcon />
      </a>
      <a
        className={styles.link}
        href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
          `${title}\n${description}`,
        )}&url=https://pry.co${url}`}
        target="_blank"
        rel="noreferrer"
        aria-label="Share on Twitter"
      >
        <TwitterIcon />
      </a>
    </div>
  );
}
