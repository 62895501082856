import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import MarketingLayout from '@components/MarketingLayout';
import SliceZone from '@components/SliceZone';
import linkResolver from '@utils/linkResolver';
import { MarketingPageTemplateQuery } from '@typings/prismic-types';

type Props = PageProps<MarketingPageTemplateQuery>;

export function MarketingPageTemplate({ data }: Props) {
  if (!data) return null;

  const marketingPage = data.prismicMarketingPage;
  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  const { lang, type, url } = marketingPage;
  const alternateLanguages = marketingPage.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  return (
    <MarketingLayout
      banner={data.prismicBanner}
      nav={nav}
      footer={footer}
      activeDocMeta={activeDoc}
      data={marketingPage.data}
    >
      <SliceZone slices={marketingPage.data.body} />
    </MarketingLayout>
  );
}

export const query = graphql`
  query MarketingPageTemplate($uid: String, $lang: String) {
    prismicMarketingPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      url
      type
      uid
      id
      lang
      alternate_languages {
        id
        type
        lang
      }
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        page_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicMarketingPageDataBodyMarketingPageHero {
            id
            slice_label
            slice_type
            primary {
              eyebrow {
                html
                richText
                text
              }
              heading {
                html
                richText
                text
              }
              subheading {
                html
                richText
                text
              }
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ...FullWidthImageFragment
          ...PrismicMarketingCardsSectionFragment
          ...PricingGridMarketingSlice
          ... on PrismicMarketingPageDataBodyWistiaVideo {
            id
            slice_type
            slice_label
            primary {
              video_link {
                url
              }
            }
          }
          ... on PrismicMarketingPageDataBodyLargeTitle {
            id
            slice_type
            slice_label
            primary {
              title {
                html
                richText
                text
              }
            }
          }
          ... on PrismicMarketingPageDataBodyCtaBanner {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
              heading {
                html
                richText
                text
              }
              primary_cta_link {
                url
                type
                uid
              }
              primary_cta_label
              primary_cta_icon
              secondary_cta_link {
                url
                type
                uid
              }
              secondary_cta_label
              secondary_cta_icon
            }
          }
          ... on PrismicMarketingPageDataBodyPricingCards {
            id
            slice_type
            slice_label
            primary {
              vertical_margin
            }
            items {
              heading {
                html
                richText
                text
              }
              description {
                html
                richText
                text
              }
              cta_link {
                url
                type
                uid
              }
              cta_label
              monthly_payment
              yearly_payment
            }
          }
          ... on PrismicMarketingPageDataBodyFaq {
            id
            slice_type
            slice_label
            primary {
              heading {
                html
                richText
                text
              }
              cta_link {
                url
                type
                uid
              }
              cta_label
            }
            items {
              question {
                html
                richText
                text
              }
              answer {
                html
                richText
                text
              }
            }
          }
          ...StackedInfoWithImageFragment
          ...PrismicMarketingPageInfoWithImageFragment
          ...PrismicMarketingTestimonialCarouselFragment
          ...PrismicMarketingCustomerLogosFragment
          ...PrismicMarketingPartnerFragment
          ...PrismicMarketingWistiaVideoAndTextSideBySideFragment
          ...MarketingPricingCardFragment
        }
      }
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(MarketingPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
