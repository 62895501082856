import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';
import BlogPage from '@templates/BlogPage';
import DocPage from '@templates/DocPage';
import Homepage from '@templates/Homepage';
import MarketingPage from '@templates/MarketingPage';
import PartnerPage from '@templates/PartnerPage';
import PricingPage from '@templates/PricingPage';
import Button from '@components/Button';
import MarketingLayout from '@components/MarketingLayout';
import linkResolver from '@utils/linkResolver';
import {
  PrismicNav,
  PrismicFooter,
  PrismicBanner,
} from '@typings/prismic-types';
import './styles.scss';

interface Props extends PageProps {
  data: {
    prismicNav?: PrismicNav;
    prismicFooter?: PrismicFooter;
    prismicBanner?: PrismicBanner;
  };
}

const ErrorMessage = () => {
  return (
    <section className={`not-found`}>
      <h1 className='marketing-h1'>404: Oops! This page does not exist.</h1>
      <Button iconType={'Arrow'} href={`/`}>
        Take Me Home
      </Button>
    </section>
  );
};

const NotFoundPage = ({ data }: Props) => {
  if (!data) return <ErrorMessage />;

  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  return (
    <MarketingLayout
      banner={data.prismicBanner}
      nav={nav && nav}
      footer={footer && footer}
    >
      <ErrorMessage />
    </MarketingLayout>
  );
};

export const query = graphql`
  query notFoundPage($lang: String) {
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      blog_page: BlogPage,
      doc_page: DocPage,
      homepage: Homepage,
      marketing_page: MarketingPage,
      pricing: PricingPage,
      partner_page: PartnerPage,
    }),
  },
]);
