import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import DocContentLayout from '@components/DocContentLayout';
import DocLayout from '@components/DocLayout';
import { useAllPrismicDocPage } from '@utils/hooks/useAllPrismicDocPage';
import { usePrismicDocsSitemap } from '@utils/hooks/usePrismicDocsSitemap';
import linkResolver from '@utils/linkResolver';
import { DocIdMap } from '@utils/typings/custom';
import { DocPageTemplateQuery } from '@typings/prismic-types';

type Props = PageProps<DocPageTemplateQuery>;

export const DocPageTemplate = ({ data }: Props) => {
  const allPrismicDocPage = useAllPrismicDocPage();
  const prismicDocsSitemap = usePrismicDocsSitemap();

  if (!data) return null;

  const docPage = data.prismicDocPage;
  const footer = data.prismicFooter;
  const { metadata_description, metadata_image, metadata_title, title } =
    docPage.data;

  const docIdMap: DocIdMap = allPrismicDocPage.reduce(
    (obj, item) => ({
      ...obj,
      [item.prismicId]: { title: item.data.title.text, url: item.url },
    }),
    {},
  );

  const orderedPageIds = prismicDocsSitemap.data.top_level_pages
    .map((page) => page.doc_page.id)
    .concat(
      prismicDocsSitemap.data.body.flatMap((group) =>
        group.items.map((page) => page.doc_page.id),
      ),
    );

  const docMetadata = {
    title: `${metadata_title.text || title.text} - Pry`,
    description: metadata_description.text,
    image: metadata_image.url,
  };

  return (
    <DocLayout
      docsSitemap={prismicDocsSitemap}
      banner={data.prismicBanner}
      footer={footer}
      docIdMap={docIdMap}
      docMetadata={docMetadata}
      activeUrl={docPage.url}
      firstDocUrl={docIdMap[orderedPageIds[0]].url}
    >
      <DocContentLayout
        docPage={docPage}
        docsSitemap={prismicDocsSitemap}
        orderedPageIds={orderedPageIds}
        docIdMap={docIdMap}
      />
    </DocLayout>
  );
};

export const query = graphql`
  query DocPageTemplate($uid: String, $lang: String) {
    prismicDocPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      ...PrismicDocPageFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(DocPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
