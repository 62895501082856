import React from 'react';
import Authors from '@components/BlogAuthors';
import Metadata from '@components/BlogMetadata';
import {
  PrismicAuthorFragmentFragment,
  PrismicBlogPageFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  blog: PrismicBlogPageFragmentFragment;
  authors: PrismicAuthorFragmentFragment[];
};

export default function BlogHeader({ blog, authors }: Props) {
  return (
    <div>
      <Metadata blogPage={blog} />
      <h1 className={styles.title}>{blog.data.title.text}</h1>
      <div className={styles.subtitle}>{blog.data.subtitle.text}</div>
      <div className={styles.authors}>
        <Authors authors={authors} />
      </div>
    </div>
  );
}
