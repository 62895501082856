// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--G9P5W";
export var container = "styles-module--container--lvoJR";
export var keepReading = "styles-module--keep-reading--qx22N";
export var empty = "styles-module--empty--QUQqd";
export var metadata = "styles-module--metadata--0TbPw";
export var dateAndTime = "styles-module--date-and-time--Quys0";
export var date = "styles-module--date--i9orz";
export var readTime = "styles-module--read-time--bB3nW";
export var tags = "styles-module--tags--9pJZ9";
export var tag = "styles-module--tag--vViTB";
export var content = "styles-module--content--IHkNY";
export var main = "styles-module--main--RAeYO";
export var title = "styles-module--title--xUkT4";
export var description = "styles-module--description--4V5CA";