import { useStaticQuery, graphql } from 'gatsby';
import { UsePrismicSitemapQuery } from '@utils/typings/prismic-types';

export function usePrismicDocsSitemap() {
  const query = useStaticQuery<UsePrismicSitemapQuery>(graphql`
    query UsePrismicSitemap {
      prismicDocsSitemap {
        ...PrismicDocsSitemapFragment
      }
    }
  `);

  return query.prismicDocsSitemap;
}
