import { useStaticQuery, graphql } from 'gatsby';
import { UseAllPrismicDocPageQuery } from '@utils/typings/prismic-types';

export function useAllPrismicDocPage() {
  const query = useStaticQuery<UseAllPrismicDocPageQuery>(graphql`
    query UseAllPrismicDocPage {
      allPrismicDocPage {
        nodes {
          ...PrismicDocPageFragment
        }
      }
    }
  `);

  return query.allPrismicDocPage.nodes;
}
