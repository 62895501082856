import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import BlogContentLayout from '@components/BlogContentLayout';
import BlogLayout from '@components/BlogLayout';
import SliceZone from '@components/SliceZone';
import { useAllPrismicAuthor } from '@utils/hooks/useAllPrismicAuthor';
import { useAllPrismicBlogPage } from '@utils/hooks/useAllPrismicBlogPage';
import linkResolver from '@utils/linkResolver';
import {
  BlogPageTemplateQuery,
  PrismicBlogPageFragmentFragment,
} from '@typings/prismic-types';

type Props = PageProps<BlogPageTemplateQuery>;

export function BlogPageTemplate({ data }: Props) {
  const allPrismicAuthor = useAllPrismicAuthor();
  const allPrismicBlogPage = useAllPrismicBlogPage();

  if (!data) return null;

  const blog = data.prismicBlogPage;
  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  const activeBlogMeta = {
    url: blog.url,
    title: blog.data.title.text,
    description: blog.data.subtitle.text,
    imageUrl: blog.data.metadata_image?.url,
    lang: blog.lang,
  };

  const nextBlogPage = getNextBlogPage(allPrismicBlogPage, blog);

  return (
    <BlogLayout
      banner={data.prismicBanner}
      nav={nav}
      footer={footer}
      activeBlogMeta={activeBlogMeta}
    >
      <BlogContentLayout
        blog={blog}
        allAuthors={allPrismicAuthor}
        activeBlogMeta={activeBlogMeta}
        nextBlogPage={nextBlogPage}
      >
        <SliceZone slices={blog.data.body} blog={blog} />
      </BlogContentLayout>
    </BlogLayout>
  );
}

function getNextBlogPage(
  allBlogPage: PrismicBlogPageFragmentFragment[],
  blog: PrismicBlogPageFragmentFragment,
) {
  const blogIndex = allBlogPage.findIndex((page) => page.id === blog.id);
  let nextBlogPage = null;
  if (blogIndex !== allBlogPage.length - 1) {
    nextBlogPage = allBlogPage[blogIndex + 1];
  } else if (blogIndex > 0) {
    nextBlogPage = allBlogPage[blogIndex - 1];
  }
  return nextBlogPage;
}

export const query = graphql`
  query BlogPageTemplate($uid: String, $lang: String) {
    prismicBlogPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      ...PrismicBlogPageFragment
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(BlogPageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
