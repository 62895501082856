import { PryEventSchemaMap } from './events';

type EventsKeys = keyof PryEventSchemaMap;

type TrackFunctionType = <TEventName extends EventsKeys>(
  eventName: TEventName,
  eventProperties?: any,
  callback?: () => void,
) => void;

export const trackEvent: TrackFunctionType = (
  eventName,
  eventProperties,
  callback,
) => {
  // Use segment only for production
  if (process.env.NODE_ENV === 'development') {
    // Log to console during development
    // eslint-disable-next-line no-console
    console.info(
      `%c Tracking %c ${eventName}`,
      'color: orange',
      'color: limegreen',
      eventProperties,
    );
  } else {
    try {
      window.analytics.track(
        eventName,
        {
          ...eventProperties,
        },
        callback,
      );
    } catch (err) {
      console.error('Failed to track event in Segment');
      console.error(err);
    }
  }
};
