import React from 'react';
import { Helmet } from 'react-helmet';
import Banner from '@components/Banner';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import {
  NavFragmentFragment,
  FooterFragmentFragment,
  PrismicBannerFragmentFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  banner: PrismicBannerFragmentFragment;
  nav?: NavFragmentFragment;
  footer?: FooterFragmentFragment;
  activeBlogMeta?: {
    url?: string;
    title?: string;
    description?: string;
    imageUrl?: string;
    lang?: string;
  };
};

const BlogLayout = ({
  children,
  banner,
  nav,
  footer,
  activeBlogMeta,
}: Props) => {
  return (
    <div className="typography blog-typography">
      <Helmet>
        <title>{`${activeBlogMeta.title || 'Blog'} | Pry`}</title>
        <meta property="og:url" content={activeBlogMeta.url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={activeBlogMeta.title} />
        <meta property="og:description" content={activeBlogMeta.description} />
        <meta property="og:image" content={activeBlogMeta.imageUrl} />
      </Helmet>
      <Banner banner={banner} />
      <div className="blog">
        <Nav nav={nav} sticky={false} />
      </div>
      <main className={styles.main}>{children}</main>
      <Footer footer={footer} />
    </div>
  );
};

BlogLayout.propTypes = {
  children: Number,
};

export default BlogLayout;
