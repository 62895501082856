import React from 'react';
import { graphql, Link } from 'gatsby';
import BlogHeader from '@components/BlogHeader';
import BlogKeepReading from '@components/BlogKeepReading';
import BlogShareLinks from '@components/BlogShareLinks';
import {
  PrismicAuthorFragmentFragment,
  PrismicBlogPageFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';
import { SubscribeForm } from '@components/SubscribeForm';

interface Props {
  blog: PrismicBlogPageFragmentFragment;
  allAuthors: PrismicAuthorFragmentFragment[];
  activeBlogMeta: {
    url: string;
    title: string;
    description: string;
    imageUrl: string;
    lang: string;
  };
  nextBlogPage: PrismicBlogPageFragmentFragment;
  children: React.ReactNode;
}

export default function BlogContentLayout({
  blog,
  allAuthors,
  activeBlogMeta,
  nextBlogPage,
  children,
}: Props) {
  const activeBlogAuthors = allAuthors.filter((author) =>
    blog.data.authors.some(
      (blogAuthor) => blogAuthor.author.id === author.prismicId,
    ),
  );
  const nextBlogAuthors = allAuthors.filter((author) =>
    nextBlogPage.data.authors.some(
      (blogAuthor) => blogAuthor.author.id === author.prismicId,
    ),
  );

  return (
    <div>
      <div className="relative">
        <Sidebar activeBlogMeta={activeBlogMeta} />
        <div className={styles.header}>
          <div className="w-full flex justify-end mb-8 -mt-4">
            <SubscribeForm />
          </div>
          <BlogHeader blog={blog} authors={activeBlogAuthors} />
        </div>
        {children}
      </div>
      {nextBlogPage && (
        <div className={styles.keepReading}>
          <BlogKeepReading nextPage={nextBlogPage} authors={nextBlogAuthors} />
        </div>
      )}
    </div>
  );
}

interface SidebarProps {
  activeBlogMeta: {
    url: string;
    title: string;
    description: string;
    imageUrl: string;
    lang: string;
  };
}
function Sidebar({ activeBlogMeta }: SidebarProps) {
  return (
    <div className={styles.sidebar}>
      <Link to="/blog" className={styles.blogLink}>
        <h2 className={styles.linkText}>Pry Blog</h2>
      </Link>
      <div className={styles.share}>
        <BlogShareLinks activeBlogMeta={activeBlogMeta} />
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PrismicBlogPageFragment on PrismicBlogPage {
    _previewable
    url
    uid
    type
    id
    prismicId
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    last_publication_date
    first_publication_date
    tags
    data {
      title {
        text
      }
      subtitle {
        text
      }
      publish_date
      metadata_image {
        url
        alt
      }
      authors {
        author {
          id
          uid
        }
      }
      body {
        ... on PrismicBlogPageDataBodyBlogExtraWideImage {
          id
          slice_type
          slice_label
          primary {
            image {
              alt
              url
            }
            caption {
              text
            }
          }
        }
        ... on PrismicBlogPageDataBodyBlogImageWithCaption {
          id
          slice_type
          slice_label
          primary {
            image {
              url
              alt
            }
            caption {
              text
            }
          }
        }
        ... on PrismicBlogPageDataBodyBlogRichText {
          id
          slice_type
          primary {
            content {
              richText
              text
            }
          }
          slice_label
        }
        ... on PrismicBlogPageDataBodyBlogExtraWideVideo {
          id
          slice_type
          slice_label
          primary {
            embed {
              id
            }
            caption {
              text
            }
          }
        }
        ...SideBySideImagesSlice
        ... on PrismicBlogPageDataBodyTableOfContents {
          id
          slice_label
          slice_type
        }
      }
    }
  }
`;
